/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import Popup from "vux/src/components/popup/index.vue";
import Checklist from "vux/src/components/checklist/index.vue";
import sysuser from '@/components/common/SysUser';
import sysgroup from '@/components/common/SysGroup';
import rxboxs from '@/components/common/rx-boxs';
import RxAttachment from '@/components/common/form/RxAttachment';
import router from '@/router/index';
import { RxUtil } from "@/assets/util.js";
import { convertBoJson } from '@/assets/app.js';
import { getNodeUserJson } from '@/assets/flow/bpmtask.js';
Vue.component(sysuser.name, sysuser);
Vue.component(sysgroup.name, sysgroup);
Vue.component(rxboxs.name, rxboxs);
Vue.component(RxAttachment.name, RxAttachment);
Vue.component(Popup.name, Popup);
Vue.component(Checklist.name, Checklist);
export default {
  name: "rx-approve",
  data() {
    return {
      bpmTask: {},
      destNodeUserList: [],
      taskConfig: {},
      opinion: "",
      //目标节点
      files: "[]",
      destNodeId: "",
      jumpType: [],
      nextJumpType: [],
      opinion: "",
      opFiles: [],
      taskId: "",
      nodeName: "",
      showPop: false,
      actions: [{
        key: 'BACK',
        value: '驳回(上一节点)'
      }, {
        key: 'BACK_TO_STARTOR',
        value: '驳回(发起人)'
      }, {
        key: 'BACK_SPEC',
        value: '驳回指定节点'
      }],
      nextJumpTypeJson: [{
        key: 'normal',
        value: '重走流程'
      }, {
        key: 'orgPathReturn',
        value: '原路返回'
      }],
      nodeJson: [],
      checkValue: []
    };
  },
  watch: {},
  mounted() {
    var data = this.$store.state.bpmModule.taskData;
    this.bpmTask = data.bpmTask;
    var bpmTask = data.bpmTask;
    var url = _baseUrl + "/bpm/core/bpmRuPath/getBackNodes/" + bpmTask.procInstId + "/" + bpmTask.taskDefKey + ".do";
    this.$ajax.get(url).then(response => {
      if (response.status == 200) {
        var nodes = response.data;
        var nodeAry = [];
        for (let i = 0, j = nodes.length; i < j; i++) {
          var node = nodes[i];
          var obj = {};
          obj.key = node.nodeId;
          obj.value = node.nodeName;
          nodeAry.push(obj);
        }
        this.nodeJson = nodeAry;
      }
    }).catch(error => {
      console.log(error);
    });
    this.listJumpType();
  },
  computed: {},
  methods: {
    listJumpType() {
      var params = "taskId=" + this.bpmTask.id;
      var url = _baseUrl + "/bpm/core/bpmTask/listJumpTypes.do";
      this.$ajax.post(url, params).then(res => {
        if (res.data.success) {
          var jumps = res.data.data;
          if (jumps && jumps.length > 0) {
            this.actions = jumps.map(item => {
              return {
                key: item.id,
                value: item.text
              };
            });
          }
        }
      }).catch(error => {
        console.log(error);
      });
    },
    change(val) {
      if (val == 'BACK_SPEC') {
        this.showPop = true;
      } else {
        this.nodeName = "";
        this.destNodeId = "";
      }
    },
    cancel() {
      router.go(-1);
    },
    onCancel() {
      this.showPop = false;
    },
    onChange() {
      var checkValue = this.checkValue;
      var nodeJson = this.nodeJson;
      var names = [];
      for (let i = 0, j = nodeJson.length; i < j; i++) {
        for (let m = 0, n = checkValue.length; m < n; m++) {
          if (checkValue[m] == nodeJson[i].key) {
            names.push(nodeJson[i].value); //显示值
            break;
          }
        }
      }
      this.destNodeId = checkValue.toString();
      this.nodeName = names.toString();
      this.showPop = false;
    },
    handleReject() {
      if (this.jumpType.length == 0) {
        this.errorToast("没有选择驳回类型", "1500");
        return;
      } else if (this.jumpType[0] == 'BACK_SPEC' && !this.destNodeId) {
        this.errorToast("没有选择驳回节点", "1500");
        return;
      }
      if (this.nextJumpType.length == 0) {
        this.errorToast("请选择回退任务后的操作", "1500");
        return;
      }
      var approveCmd = {
        destNodeId: this.destNodeId,
        jumpType: this.jumpType,
        nextJumpType: this.nextJumpType,
        opinion: this.opinion,
        opFiles: this.files,
        taskId: this.bpmTask.id
      };
      //加上表单数据
      var data = this.$store.state.bpmModule.taskData;
      var jsonData = convertBoJson(data.formModels);
      let jsonStr = this.replaceUnicode(jsonData);
      approveCmd.jsonData = jsonStr;
      var url = _baseUrl + "/bpm/core/bpmTask/doNext.do";
      var params = RxUtil.serialJsonToForm(approveCmd);
      this.$dialog.loading.open("正在驳回...");
      this.$ajax.post(url, params).then(response => {
        this.$dialog.loading.close();
        var data = response.data;
        if (data.success) {
          this.successToast("驳回成功！", 1500, 'index', {
            from: 'reject'
          });
        } else {
          this.errorToast(data.message, "1500");
        }
      }).catch(error => {
        this.$dialog.loading.close();
        console.log(error);
      });
    },
    goMyToDo() {
      router.push({
        name: "index"
      });
    }
  }
};